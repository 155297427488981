<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flexspabet"
				@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
				<div style="margin-bottom: 10px">
					<el-button @click="showEdit=true" size="small" v-if="permission.includes('system:vmareas:add')"
						class="ele-btn-icon addbtn ">添加开通城市
					</el-button>
				</div>
			</el-form>
			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row stripe class="my-dialog-name">
				<template slot-scope="{index}">
					<el-table-column type="index" :index="index" label="序号" width="60px" align="center"
						show-overflow-tooltip />
					<el-table-column prop="role_name" label="管理员" show-overflow-tooltip width="220px" />
					<el-table-column prop="address" label="地区" show-overflow-tooltip width="220px" />
					<el-table-column prop="real_name" label="真实姓名" show-overflow-tooltip width="200" />
					<el-table-column prop="mobile" label="手机号" show-overflow-tooltip width="200" />
          <el-table-column label="开放状态"  show-overflow-tooltip min-width="100px">
            <template slot-scope="scope">
              <span>{{scope.row.status==1?'开启':'关闭'}}</span>
            </template>
          </el-table-column>
					<el-table-column label="操作" width="400px" align="center" :resizable="false">
						<template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('system:vmareas:edit')">修改</el-link>
              <el-link @click="open(row, 0)" icon="el-icon-view" type="danger" :underline="false" v-if="row.status ==1 && permission.includes('system:vmareas:open')">关闭</el-link>
							<el-link @click="open(row, 1)" icon="el-icon-view" type="primary" :underline="false" v-if="row.status ==0 && permission.includes('system:vmareas:open')">开启</el-link>
							<el-link @click="mobile(row)" icon="el-icon-view" type="primary" :underline="false" v-if="permission.includes('system:vmareas:mobile')">查看手机号</el-link>
              <el-link @click="remove(row)" icon="el-icon-delete" type="danger" :underline="false"
                       v-if="permission.includes('system:vmareas:delete')">删除
              </el-link>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 添加弹窗 -->
		<el-dialog v-dialogDrag :title="editForm.id?'编辑开通城市':'添加开通城市'" :visible.sync="showEdit" @closed="closeEdit(editForm)"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">
          <div>
            <el-form-item label="地区：" prop="pname">
              <el-select v-model="editForm.pname" @change="handleChangeProv2(editForm.pname)"
                         placeholder="请选择省" class="selectStyle mr-10 mb-20" clearable>
                <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                           :label="option.name"></el-option>
              </el-select>
              <el-select v-model="editForm.cname" @change="handleChangeCity2(editForm.cname)"
                         placeholder="请选择市" class="selectStyle mr-10 mb-20" clearable>
                <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                           :label="option.name"></el-option>
              </el-select>
              <el-select v-model="editForm.aname" @change="$forceUpdate(),handleDis(editForm.aname)"
                         placeholder="请选择区/县" class="selectStyle mr-10 mb-20" clearable>
                <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                           :label="option.name"></el-option>
              </el-select>
            </el-form-item>
          </div>
					<el-form-item label="真实姓名：" prop="edit_real_name">
						<el-input type="text" v-model="editForm.edit_real_name" aria-placeholder="请输入真实姓名" class="input216">
						</el-input>
					</el-form-item>
					<el-form-item label="手机号：" prop="edit_mobile">
						<el-input type="text" v-model="editForm.edit_mobile" aria-placeholder="请输入手机号" class="input216">
						</el-input>
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="showEdit=false">取消</el-button>
				<el-button type="primary" @click="save">提交</el-button>
			</div>
		</el-dialog>

    <!-- 添加手机号弹窗 -->
		<el-dialog v-dialogDrag :title="editFormMobile.id?'编辑手机号':'添加手机号'" :visible.sync="showEditMobile" @closed="editFormMobile={}"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editFormMobile" ref="editForm" :rules="editRulesMobile" label-width="100px">
					<el-form-item label="手机号：" prop="mobile">
						<el-input type="text" v-model="editFormMobile.mobile" aria-placeholder="请输入手机号" class="input216">
						</el-input>
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="showEditMobile=false">取消</el-button>
				<el-button type="primary" @click="savemobile">提交</el-button>
			</div>
		</el-dialog>

<!--    手机号列表-->
    <el-dialog v-dialogDrag title="检测记录" :visible.sync="dialogTableVisibleRecords" width="1000px"
               :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <el-button @click="showEditMobile=true" size="small" class="ele-btn-icon addbtn " style="margin-bottom: 10px;">添加手机号</el-button>

        <el-table :data="recordsList" element-loading-text="列表正在加载中" border fit highlight-current-row
                  style="width: 100%;">
          <el-table-column type="selection" width="45" align="center" fixed="left" />
          <el-table-column type="index" label="编号" width="60" align="center" fixed="left"
                           show-overflow-tooltip />
          <el-table-column prop="mobile" label="手机号" show-overflow-tooltip min-width="120" />
          <el-table-column label="操作" width="400px" align="center" :resizable="false">
            <template slot-scope="{row}">
              <el-link @click="editmobile(row)" icon="el-icon-view" type="primary" :underline="false">编辑
              </el-link>
              <el-link @click="removemobile(row)" icon="el-icon-delete" type="danger" :underline="false" >删除
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination v-if="recordsShow" @current-change="handleCurrentChange" :current-page.sync="pageNumber"
                       layout="total, prev, pager, next, jumper" :page-sizes="[5, 10, 15, 20]" :page-size="100"
                       :total="recordsTotal">
        </el-pagination>
      </el-card>
    </el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		name: "SysCountyCircle",
		components: {

		},
		data() {
			return {
				table: {
					url: '/VmAreas/index',
					where: {}
				}, // 表格配置
				provArr: [],
				cityArr: [],
				districtArr: [],
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
        showEditMobile: false, // 是否显示表单弹窗
        dialogTableVisibleRecords: false, // 是否显示表单弹窗
				editForm: {}, // 表单数据
        editFormMobile: {}, // 表单数据
        recordsList: {}, // 表单数据
				userInfo:{},
				editRules: { // 表单验证规则
          pname: [{
						required: true,
						message: '请选择地区',
						trigger: "change"
					}],
          edit_real_name: [{
						required: true,
						message: '请输入真实姓名',
						trigger: "blur"
					}],
          edit_mobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
				},
				editRulesMobile: { // 表单验证规则
          mobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
				},
        editp: false,
        editc: false,
        edita: false,
        pageSize: 10,
        pageNumber: 1,
        recordsTotal: 1000,
        recordsShow: false,
        recordsId: '',
        loading: true,
			}
		},
		created() {
      this.$http.get('/common/province_list').then(res => {
        let data = JSON.parse(res.data)
        this.provArr = data
      })
		},
		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {
			this.getUserInfo();
		},
		methods: {
			/* 获取当前用户信息 */
			getUserInfo() {
				this.$http.get(this.$setting.userUrl).then(res => {
					if (res.data.code === 0) {
						var info = JSON.parse(JSON.stringify(res.data.data))
						this.userInfo = info
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					this.$message.error(e.message);
				})
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.area_id = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			/**
			 *修改省
			 **/
			handleChangeProv1(e) {
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr.forEach(function(item) {
					if (item.name == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cname = ''
					this.editForm.aname = ''
				})
			},
			/**
			 *修改市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				var cid = ''
				this.cityArr.forEach(function(item) {
					if (item.name == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''
				})
				}else{
					this.editForm.pname = ''
					this.editForm.aname = ''
				}
			},
			/**
			 * 显示编辑
			 */
			edit(row) {
				const loading = this.$loading({ lock: true });
				this.editForm = row;
				if(this.editForm){
					this.showEdit = true;
					loading.close();
				}
			},
      // 开启/关闭
      open(row, status) {
        const loading = this.$loading({lock: true});
        this.$http.post('/VmAreas/status', {id: row.id, status: status}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      },
      closeEdit(editForm){
        this.$refs.table.reload();
      },
      /**
       *分页
       **/
      handleCurrentChange(e) {
        //console.log(e)
        this.pageNumber=e
        this.getRecords(this.recordsId)
      },
      mobile(row) {
        this.dialogTableVisibleRecords = true
        this.recordsId = row.id
        this.getRecords(row.id)
      },
      getRecords(id) {
        this.recordsList = [];
        var formData = {
          area_id: id,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,

        }
        this.$http.post('/VmMobiles/index', formData).then(res => {
          var data = res.data;
          this.recordsTotal = data.total
          if (data.total == 0 || data.total == 'null') {
            this.recordsShow = false
          } else {
            this.recordsShow = true
          }
          if (data.data.length) {
            this.recordsList = data.data

          }
        })
      },
			/**
			 * 保存编辑
			 */
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						if (this.editp) {
              this.editForm.pid = this.editForm.pname
            }
						if (this.editc) {
              this.editForm.cid = this.editForm.cname
            }
						if (this.edita) {
              this.editForm.aid = this.editForm.aname
            }
						//替换页面
            this.editForm.mobile = this.editForm.edit_mobile
            this.editForm.real_name = this.editForm.edit_real_name

						this.$http.post('/VmAreas/edit', this.editForm).then(res => {
							loading.close();
              if (res.data.code === 0) {
                this.showEdit = false;
                this.$refs.table.reload();
              } else {
                this.$message.error(res.data.msg);
              }
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			/**
			 * 保存编辑
			 */
      savemobile() {
				// this.$refs['editFormMobile'].validate((valid) => {
				// 	if (valid) {
						const loading = this.$loading({
							lock: true
						});
            this.editFormMobile.area_id = this.recordsId;

						this.$http.post('/VmMobiles/edit', this.editFormMobile).then(res => {
							loading.close();
              if (res.data.code === 0) {
                this.showEditMobile = false;
                this.getRecords(this.recordsId)
              } else {
                this.$message.error(res.data.msg);
              }
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					// } else {
					// 	return false;
					// }
				// });
			},
      editmobile(row) {
        this.showEditMobile = true;
        this.editFormMobile = row;
      },
      // 删除手机号
      removemobile(row) {
        this.$confirm('确定删除手机号吗？', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          });
          this.$http.post('/VmMobiles/delete', {
            id: row.id,
          }).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.getRecords(this.recordsId)
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })

      },

      handleChangeProv2(e) {
        /** 获取被选省省份的pid**/
        this.editp = true
        let pid = ''
        this.provArr.forEach(function(item) {
          if (item.pid == e) {
            pid = item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.cityArr = data
          /** 选择省份清空市县**/
          this.editForm.cname = ''
          this.editForm.aname = ''
        })
      },
      /**
       *选择市
       **/
      handleChangeCity2(e) {
        if(e){
          /** 获取被选市的cid**/
          this.editc = true
          let cid = ''
          this.cityArr.forEach(function(item) {
            if (item.cid == e) {
              cid = item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
            cid: cid
          }).then(res => {
            let data = JSON.parse(res.data)
            this.districtArr = data
            /** 选择省份清空县**/
            this.editForm.aname = ''
          })
        }else{
          this.editForm.pname = ''
          this.editForm.aname = ''
        }
      },
      handleDis() {
        this.edita = true
      },
      // 删除
      remove (row) {
        this.$confirm('确定要删除选中的信息吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true})
          this.$http.post('/VmAreas/delete', {id: row.id}).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg})
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        })
      },
		}
	}
</script>

<style scoped>
.ele-block>>>.el-upload,
.ele-block>>>.el-upload-dragger {
	width: 100%;
}
/deep/.el-dialog{
	margin-top: 30px !important;
}
</style>